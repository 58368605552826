export const photos = [
    {
        id: "85453C54+7C",
        src: "https://vgb-img.s3.amazonaws.com/featured/85453C54.7C.webp",
        width: 906,
        height: 601,
    },
    {
        id: "854529W7+73",
        src: "https://vgb-img.s3.amazonaws.com/featured/854529W7.73.webp",
        width: 1440,
        height: 977,
    },
    {
        id: "8545297F+XC",
        src: "https://vgb-img.s3.amazonaws.com/featured/8545297F.XC.webp",
        width: 2048,
        height: 1536,
    },
    {
        id: "8545294C+98",
        src: "https://vgb-img.s3.amazonaws.com/featured/8545294C.98.webp",
        width: 1440,
        height: 1440,
    },
    {
        id: "854539HH+VQ",
        src: "https://vgb-img.s3.amazonaws.com/featured/854539HH.VQ.webp",
        width: 1080,
        height: 1080,
    },
    {
        id: "85453C98+W3",
        src: "https://vgb-img.s3.amazonaws.com/featured/85453C98.W3.webp",
        width: 1200,
        height: 800,
    },
    {
        id: "8545288M+GP",
        src: "https://vgb-img.s3.amazonaws.com/featured/8545288M.GP.webp",
        width: 960,
        height: 720,
    },
    {
        id: "85454C38+9W",
        src: "https://vgb-img.s3.amazonaws.com/featured/85454C38.9W.webp",
        width: 1440,
        height: 960,
    },
    {
        id: "854528VX+42",
        src: "https://vgb-img.s3.amazonaws.com/featured/854528VX.42.webp",
        width: 1440,
        height: 960,
    },
    {
        id: "8545495X+GG",
        src: "https://vgb-img.s3.amazonaws.com/featured/8545495X.GG.webp",
        width: 960,
        height: 720,
    },
    {
        id: "854529C2+FP",
        src: "https://vgb-img.s3.amazonaws.com/featured/854529C2.FP.webp",
        width: 1080,
        height: 1080,
    },
    {
        id: "85452964+C7",
        src: "https://vgb-img.s3.amazonaws.com/featured/85452964.C7.webp",
        width: 2048,
        height: 1365,
    },
    {
        id: "8535X9R7+VQ",
        src: "https://vgb-img.s3.amazonaws.com/featured/8535X9R7.VQ.webp",
        width: 1200,
        height: 1200,
    },
    {
        id: "8535W7V7+5F",
        src: "https://vgb-img.s3.amazonaws.com/featured/8535W7V7.5F.webp",
        width: 2048,
        height: 1151,
    },
    {
        id: "854538HX+X2",
        src: "https://vgb-img.s3.amazonaws.com/featured/854538HX.X2.webp",
        width: 2048,
        height: 1365,
    },
    {
        id: "8535X9R6+5W",
        src: "https://vgb-img.s3.amazonaws.com/featured/8535X9R6.5W.webp",
        width: 2048,
        height: 1512,
    },
    {
        id: "8545492M+F6",
        src: "https://vgb-img.s3.amazonaws.com/featured/8545492M.F6.webp",
        width: 2048,
        height: 1365,
    },
    {
        id: "85453CV7+V2",
        src: "https://vgb-img.s3.amazonaws.com/featured/85453CV7.V2.webp",
        width: 1080,
        height: 1080,
    },
    {
        id: "8545298F+8P",
        src: "https://vgb-img.s3.amazonaws.com/featured/8545298F.8P.webp",
        width: 960,
        height: 960,
    },
    {
        id: "85453C99+HF",
        src: "https://vgb-img.s3.amazonaws.com/featured/85453C99.HF.webp",
        width: 2840,
        height: 1896,
    },
    {
        id: "8545389W+5F",
        src: "https://vgb-img.s3.amazonaws.com/featured/8545389W.5F.webp",
        width: 1067,
        height: 779,
    },
    {
        id: "8535X982+MR",
        src: "https://vgb-img.s3.amazonaws.com/featured/8535X982.MR.webp",
        width: 1536,
        height: 2048,
    },
    {
        id: "854529J8+X6",
        src: "https://vgb-img.s3.amazonaws.com/featured/854529J8.X6.webp",
        width: 960,
        height: 466,
    },
    {
        id: "8545298G+R9",
        src: "https://vgb-img.s3.amazonaws.com/featured/8545298G.R9.webp",
        width: 960,
        height: 720,
    },
    {
        id: "8535W885+JV",
        src: "https://vgb-img.s3.amazonaws.com/featured/8535W885.JV.webp",
        width: 1630,
        height: 860,
    },
    {
        id: "8545282H+VM",
        src: "https://vgb-img.s3.amazonaws.com/featured/8545282H.VM.webp",
        width: 1440,
        height: 1440,
    },
    {
        id: "854539X8+M8",
        src: "https://vgb-img.s3.amazonaws.com/featured/854539X8.M8.webp",
        width: 1057,
        height: 1057,
    },
    {
        id: "85453C55+M3+jpeg",
        src: "https://vgb-img.s3.amazonaws.com/featured/85453C55.M3.jpeg.webp",
        width: 1500,
        height: 1000,
    },
    {
        id: "8535X973+5H",
        src: "https://vgb-img.s3.amazonaws.com/featured/8535X973.5H.webp",
        width: 1688,
        height: 2015,
    },
    {
        id: "85454FQ9+9X",
        src: "https://vgb-img.s3.amazonaws.com/featured/85454FQ9.9X.webp",
        width: 1419,
        height: 540,
    },
    {
        id: "85452839+7R",
        src: "https://vgb-img.s3.amazonaws.com/featured/85452839.7R.webp",
        width: 960,
        height: 960,
    },
    {
        id: "854539Q4+7P",
        src: "https://vgb-img.s3.amazonaws.com/featured/854539Q4.7P.webp",
        width: 1280,
        height: 853,
    },
    {
        id: "8535X8R9+69",
        src: "https://vgb-img.s3.amazonaws.com/featured/8535X8R9.69.webp",
        width: 2048,
        height: 1366,
    },
    {
        id: "8535X9V7+V6",
        src: "https://vgb-img.s3.amazonaws.com/featured/8535X9V7.V6.webp",
        width: 1450,
        height: 924,
    },
    {
        id: "8535X8XH+G9",
        src: "https://vgb-img.s3.amazonaws.com/featured/8535X8XH.G9.webp",
        width: 575,
        height: 425,
    },
    {
        id: "854529PW+9R",
        src: "https://vgb-img.s3.amazonaws.com/featured/854529PW.9R.webp",
        width: 960,
        height: 699,
    },
    {
        id: "85454C2H+49",
        src: "https://vgb-img.s3.amazonaws.com/featured/85454C2H.49.webp",
        width: 1862,
        height: 2015,
    },
    {
        id: "854539PG+GX",
        src: "https://vgb-img.s3.amazonaws.com/featured/854539PG.GX.webp",
        width: 1440,
        height: 1440,
    },
    {
        id: "854529P5+8J",
        src: "https://vgb-img.s3.amazonaws.com/featured/854529P5.8J.webp",
        width: 1200,
        height: 1200,
    },
    {
        id: "85452955+W2",
        src: "https://vgb-img.s3.amazonaws.com/featured/85452955.W2.webp",
        width: 963,
        height: 959,
    },
    {
        id: "8545385W+HX",
        src: "https://vgb-img.s3.amazonaws.com/featured/8545385W.HX.webp",
        width: 1080,
        height: 1080,
    },
    {
        id: "8535X8XG+FQ",
        src: "https://vgb-img.s3.amazonaws.com/featured/8535X8XG.FQ.webp",
        width: 413,
        height: 275,
    },
    {
        id: "8535XCC6+J6",
        src: "https://vgb-img.s3.amazonaws.com/featured/8535XCC6.J6.webp",
        width: 944,
        height: 788,
    },
    {
        id: "854539QC+W2",
        src: "https://vgb-img.s3.amazonaws.com/featured/854539QC.W2.webp",
        width: 1600,
        height: 1200,
    },
    {
        id: "854529MQ+2J",
        src: "https://vgb-img.s3.amazonaws.com/featured/854529MQ.2J.webp",
        width: 1944,
        height: 1296,
    },
    {
        id: "854528HM+V4",
        src: "https://vgb-img.s3.amazonaws.com/featured/854528HM.V4.webp",
        width: 1080,
        height: 1350,
    },
    {
        id: "854539XH+Q2",
        src: "https://vgb-img.s3.amazonaws.com/featured/854539XH.Q2.webp",
        width: 1080,
        height: 1080,
    },
    {
        id: "8535X9FH+Q5",
        src: "https://vgb-img.s3.amazonaws.com/featured/8535X9FH.Q5.webp",
        width: 2048,
        height: 1678,
    },
    {
        id: "854529F6+W6",
        src: "https://vgb-img.s3.amazonaws.com/featured/854529F6.W6.webp",
        width: 693,
        height: 693,
    },
    {
        id: "85453CQ7+VM",
        src: "https://vgb-img.s3.amazonaws.com/featured/85453CQ7.VM.webp",
        width: 3906,
        height: 3906,
    },
    {
        id: "8545492H+3H",
        src: "https://vgb-img.s3.amazonaws.com/featured/8545492H.3H.webp",
        width: 1232,
        height: 821,
    },
    {
        id: "8545288P+RH",
        src: "https://vgb-img.s3.amazonaws.com/featured/8545288P.RH.webp",
        width: 1080,
        height: 1080,
    },
    {
        id: "854539CQ+XM",
        src: "https://vgb-img.s3.amazonaws.com/featured/854539CQ.XM.webp",
        width: 1463,
        height: 808,
    },
    {
        id: "85454C6X+85",
        src: "https://vgb-img.s3.amazonaws.com/featured/85454C6X.85.webp",
        width: 2000,
        height: 1333,
    },
    {
        id: "854528RX+96",
        src: "https://vgb-img.s3.amazonaws.com/featured/854528RX.96.webp",
        width: 1080,
        height: 719,
    },
    {
        id: "854528GG+9P",
        src: "https://vgb-img.s3.amazonaws.com/featured/854528GG.9P.webp",
        width: 960,
        height: 960,
    },
    {
        id: "8545398X+J6",
        src: "https://vgb-img.s3.amazonaws.com/featured/8545398X.J6.webp",
        width: 599,
        height: 413,
    },
    {
        id: "85454FJF+8J",
        src: "https://vgb-img.s3.amazonaws.com/featured/85454FJF.8J.webp",
        width: 430,
        height: 430,
    },
    {
        id: "854539GH+QC",
        src: "https://vgb-img.s3.amazonaws.com/featured/854539GH.QC.webp",
        width: 988,
        height: 987,
    },
    {
        id: "85453C55+M3",
        src: "https://vgb-img.s3.amazonaws.com/featured/85453C55.M3.webp",
        width: 1500,
        height: 1000,
    },
    {
        id: "854529C3+78",
        src: "https://vgb-img.s3.amazonaws.com/featured/854529C3.78.webp",
        width: 960,
        height: 718,
    },
    {
        id: "8545496W+45",
        src: "https://vgb-img.s3.amazonaws.com/featured/8545496W.45.webp",
        width: 1045,
        height: 827,
    },
    {
        id: "85452CQ4+X5",
        src: "https://vgb-img.s3.amazonaws.com/featured/85452CQ4.X5.webp",
        width: 960,
        height: 720,
    },
    {
        id: "854529G6+C2",
        src: "https://vgb-img.s3.amazonaws.com/featured/854529G6.C2.webp",
        width: 1440,
        height: 960,
    },
    {
        id: "85453CQC+5V",
        src: "https://vgb-img.s3.amazonaws.com/featured/85453CQC.5V.webp",
        width: 960,
        height: 720,
    },
    {
        id: "8535X9PF+VJ",
        src: "https://vgb-img.s3.amazonaws.com/featured/8535X9PF.VJ.webp",
        width: 1080,
        height: 810,
    },
    {
        id: "85453CRC+RP",
        src: "https://vgb-img.s3.amazonaws.com/featured/85453CRC.RP.webp",
        width: 526,
        height: 526,
    },
    {
        id: "8545398X+MW",
        src: "https://vgb-img.s3.amazonaws.com/featured/8545398X.MW.webp",
        width: 750,
        height: 600,
    },
    {
        id: "854539RR+JG",
        src: "https://vgb-img.s3.amazonaws.com/featured/854539RR.JG.webp",
        width: 2048,
        height: 1365,
    },
    {
        id: "854528QJ+4M",
        src: "https://vgb-img.s3.amazonaws.com/featured/854528QJ.4M.webp",
        width: 1440,
        height: 1439,
    },
];
