import React, { useState, useCallback } from "react";

import { Helmet } from "react-helmet";
import Gallery from "react-photo-gallery";
import Container from "@material-ui/core/Container";
import Layout from "../components/Layout";
import Showcase from "../components/Showcase";
import InfoContext from "../components/InfoContext";
import { Link } from "gatsby-theme-material-ui";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import points from "data/points.json";
import { photos } from "data/gallery.js";

const GalleryPage = () => {
    const [info, setInfo] = useState(null);

    const openLightbox = useCallback((event, { photo, index }) => {
        var picked = points.find((o) => o.properties.PlusCode === photo.id);
        setInfo(picked?.properties);
    }, []);

    return (
        <Layout pageName="gallery">
            <Helmet>
                <title>Gallery</title>
            </Helmet>
            <InfoContext.Provider value={{ info, setInfo }}>
                <Container maxWidth="md">
                    <Box>
                        <Typography
                            align="center"
                            variant="body2"
                            color="text.secondary"
                        >
                            Also visit our <Link to="/browse/">Maps</Link> page.
                        </Typography>
                    </Box>
                    <Showcase info={info} />
                    <Gallery photos={photos} onClick={openLightbox} />
                </Container>
            </InfoContext.Provider>
        </Layout>
    );
};

export default GalleryPage;
